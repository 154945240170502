* {
  box-sizing: border-box;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
.screenHeight {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
.btn {
  @apply bg-red-500 py-2 px-3 leading-4 shadow-md
}